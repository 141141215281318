<template>
<div class="page_candidate_list">
  <div class="inner">
    <div class="search_box" style="width:360px;">
      <el-input v-model="param.query" placeholder="请输入您要查询的内容"/><el-button @click="searchData" type="primary">搜索</el-button>
    </div>
    <div class="filter_area" style="margin-top:20px;">
      <el-form :inline="true">
        <el-form-item label="年龄范围:">
           <el-input type="number" min="0" v-model="param.mimAge"  placeholder="请输入"/> <div class="h_line"></div> <el-input  type="number" min="0" v-model="param.maxAge" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="工作年限:">
          <el-input  type="number" min="0" v-model="param.minWorkYear" placeholder="请输入"/> <div class="h_line"></div> <el-input  type="number" min="0" v-model="param.maxWorkYear" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="学历范围:">
<!--          <el-input  placeholder="请输入"/> -->
          <el-select v-model="param.minEducation">
            <el-option v-for="item in degreeList"
              :label="item.label"
              :value="item.value"
              :key = "item.value"
            ></el-option>
          </el-select>
          <div class="h_line"></div>
          <el-select v-model="param.maxEducation">
            <el-option v-for="item in degreeList"
                       :label="item.label"
                       :value="item.value"
                       :key = "item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchData">搜索</el-button>
          <el-button @click="clearData">清除</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table_area">
      <div class="filter_items">
        <div class="filter_item" :class="{filter_item_cur:param.status === 1}" @click="setStatus(1)">观察中</div>
        <div class="filter_item" :class="{filter_item_cur:param.status === 2}" @click="setStatus(2)">已约面</div>
        <div class="filter_item" :class="{filter_item_cur:param.status === 3}" @click="setStatus(3)">不合适</div>
        <div class="filter_item" style="margin-left:auto">
          <div class="account_info">
            <el-select v-model="param.companyPostId" @change="postChange">
              <el-option
                  v-for="item in jobList"
                  :label="item.postName"
                  :key="item.id"
                  :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="margin:25px;">
        <el-table :data="list" style="width: 100%"
                  @sort-change="sortChange"
                  @selection-change="selectionChange">
          <el-table-column type="selection" width="40"/>
          <el-table-column prop="realName" label="候选人姓名" width="150"/>
          <el-table-column prop="age" label="年龄" width="80" sortable="custom"/>
          <el-table-column prop="workYear" label="工作年限" width="120" sortable="custom"/>
          <el-table-column prop="education" label="学历" width="120"  sortable="custom">
            <template #default="scope">
              {{userStore.getDegree(scope.row.education)}}
            </template>
          </el-table-column>
          <el-table-column prop="applyDate" label="申请日期" width="180"  sortable="custom" />
          <el-table-column label="操作" width="150">
            <template #default="scope">
              <el-button
                  type="primary"
                  @click="opItem(scope.$index, scope.row,'resume')"
                  link>简历</el-button>
              <el-button
                  type="primary"
                  @click="opItem(scope.$index, scope.row,'contact')"
                  link>联系</el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="260">
            <template #default="scope">
              <el-radio-group class="ml-4" @change="rowStatusChange(scope.$index)" v-model="scope.row.status">
                <el-radio :label="1" size="large">联系中</el-radio>
                <el-radio :label="2" size="large">已约面</el-radio>
                <el-radio :label="3" size="large">不合适</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>
      </div>


    </div>
    <el-pagination background layout="prev, pager, next"
                   :page-size="param.pageSize"
                   v-model:current-page="param.pageNum"
                   :total="total"
                   @current-change="currentChange"/>
  </div>
</div>
</template>

<script>
import {getApplyUserList, jobSelectList, updateApplyUserStatus} from "@/api/company/company";
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
export default {
  name: "candidateList",
  data(){
    return {
      list:[],
      degreeList:[],
      selectedUserid:[],
      jobList:[],
      total:0,
      param:{
        companyPostId:"",
        "maxAge": '',
        "maxEducation": '',
        "maxWorkYear": '',
        "mimAge": '',
        "minEducation": '',
        "minWorkYear": '',
        "orderByColumn": "",
        "pageNum": 1,
        "pageSize": 10,
        "query": "",
        "sort": "",
        "status": 1
      }
    }
  },
  methods:{
    selectionChange(v){
      console.log(v);
      this.selectedUserid = [];
      for(let i in v){
        this.selectedUserid.push(v[i].userId);
      }
    },
    postChange(v) {
      this.param.companyPostId = v;
      this.param.pageNum = 1;
      this.searchData();
    },
    sortChange(v){
      console.log(v);
      if(v.order === "ascending"){
        this.param.sort = "asc";
      }else{
        this.param.sort = "desc";
      }
      this.param.orderByColumn = v.prop;
      this.param.pageNum = 1;
      this.searchData();
    },
    currentChange(v){
      this.param.pageNum = v;
      this.searchData();
    },
    opItem(index,item,act){
      if(act === "resume"){
          this.$router.push({
            path:"/resumeDetail",
            query:{
              id:item.userApplyId
            }
          });
      }else if(act === "contact"){
        this.$message({
          type:"error",
          message:"我也不知道，咋联系"
        });
      }
    },
    setStatus(s){
      this.param.status = s;
      this.searchData();
    },
    rowStatusChange(index){
      updateApplyUserStatus({
        status:this.list[index].status,
        userApplyId:this.list[index].userApplyId
      }).then(res => {
        if(res!==0){
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    },
    clearData(){
      this.param={
        "maxAge": '',
        "maxEducation": '',
        "maxWorkYear": '',
        "mimAge": '',
        "minEducation": '',
        "minWorkYear": '',
        "orderByColumn": "",
        "pageNum": 0,
        "pageSize": 0,
        "query": "",
        "sort": "",
        "status": 1
      }
    },
    searchData(){
      if(this.maxAge<this.mimAge){
        this.$message({
          type:"error",
          message:"年龄范围有误"
        });
        return ;
      }
      if(this.maxEducation<this.minEducation){
        this.$message({
          type:"error",
          message:"学历范围有误"
        });
        return ;
      }
      if(this.maxWorkYear<this.minWorkYear){
        this.$message({
          type:"error",
          message:"工作年限范围有误"
        });
        return ;
      }
      getApplyUserList(this.param).then(res => {
        if(res.code === 0){
          // res.data.rows = [{
          //   realName:"艾佛",
          //   sex:1,
          //   age:30,
          //   workYear:20,
          //   applyDate:"2014-09-18",
          //   education:1
          // },{
          //   realName:"杨梅",
          //   sex:1,
          //   age:20,
          //   workYear:20,
          //   applyDate:"2014-09-18",
          //   education:2
          // },{
          //   realName:"雨停",
          //   sex:1,
          //   age:10,
          //   workYear:20,
          //   applyDate:"2015-10-18",
          //   education:3
          // },{
          //   realName:"黑云",
          //   sex:1,
          //   age:23,
          //   workYear:20,
          //   applyDate:"2010-09-18",
          //   education:4
          // },{
          //   realName:"uu",
          //   sex:1,
          //   age:40,
          //   workYear:20,
          //   applyDate:"2012-09-18",
          //   education:5
          // }];
          // for(let i in res.data.rows){
          //   res.data.rows[i].status = 1;
          // }
          this.list = res.data.rows;
          this.total = res.data.total;

          // this.total =res.data.rows.length;
        }
      });
    }
  },
  mounted(){
    jobSelectList().then(res => {
      if(res.code === 0){
        this.jobList = res.data;
        if(this.jobList.length > 0){
          if(this.param.companyPostId === ""){
            this.param.companyPostId = this.jobList[0].id;
          }
        }
      }
      this.searchData();
    });
    this.degreeList = JSON.parse(JSON.stringify(this.userStore.degree));
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import "../../assets/css/variable";
  .page_candidate_list{
    padding-bottom:20px;
    .el-form .el-select{
      width:auto;
    }
    .el-radio{
      margin-right:8px;
    }
    .el-table__inner-wrapper::before{
      background-color:transparent;
    }
    .el-table th.el-table__cell{
      background-color:#faf9f9;
      height:50px;
    }
    .el-table td.el-table__cell{
      height:50px;
    }
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
      background-color:#faf9f9;
    }
    .el-table .caret-wrapper{
      background:url(../../assets/img/icon_sort.svg) no-repeat 2px 1px / 10px auto;
      i{
        display:none;
      }
    }
    .el-pagination{
      display: flex;
      margin-top:20px;
      justify-content: center;
    }
    .table_area{
      background-color:#fff;
      margin-top:25px;
      overflow: hidden;
      .el-button--primary.is-link{
        color:@blue_color
      }
    }
    .filter_items{
      margin:30px;
      display:flex;
      align-items: center;
      .filter_item{
        margin-right:25px;
        font-size: 18px;
        color: #333333;
        cursor:pointer;
      }
      .filter_item_cur{
        color:@blue_color;
      }
    }
    .el-form .el-form-item{
      margin-bottom:20px;
    }
    .el-form .el-input{
      width:120px;
    }
    .h_line{
      margin:0 10px;
      width:10px;
      border-top:1px solid #1a1a1a;
    }
  }

</style>
